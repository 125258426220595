import {Component, OnInit} from "@angular/core";
import {BaseFilterController} from "@amlCore/filterComponents";
import {TableService} from "@amlCore/services";
import {faTimes, faFilter, faSearch, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-search-header',
  templateUrl: './searchHeader.component.html',
  styleUrls: ['./searchHeader.component.scss']
})
export class SearchHeaderComponent implements OnInit {

  isFocus = false;
  public baseFilter: BaseFilterController;
  public isHideCloseBtn = false; // признак. для скрытия кнопки очистить фильтр
  icons = {
    filter: faFilter,
    search: faSearch,
    close: faTimes,
    circle: faTimesCircle
  };
  placeholder = '';
  search = '';
  getSelected: GetSelectedFn;

  constructor(protected tableSrv: TableService) {
  }

  ngOnInit() {
    this.tableSrv.getBaseFilter().subscribe((filter) => {
      this.baseFilter = filter as BaseFilterController;
      if (filter) {
        this.getSelected = this.baseFilter.getFilterToStr.bind(this.baseFilter) as GetSelectedFn;
        this.search = this.baseFilter.formModelService.getValue('search');
        this.placeholder = filter.placeholder;
        if(!this.search){
           this.isHideCloseBtn = false;
        }else{
           this.isHideCloseBtn = true;
        }
      } else {
        this.getSelected = null;
        this.placeholder = '';
      }
    });
  }

  /**
   * Признак скрытия поля поиска
   * * скрыт при расширенном поиске
   */
  isHideSearchInput(): boolean {
    return this.baseFilter.hasFilterActiveEnable || this.getSelected() !== '';
  }

  /**
   * Простой поиск
   */
  onSearch() {
    this.isHideCloseBtn = true;
    if (this.isHideSearchInput()) {
      return;
    }
    this.baseFilter.onSearchEmit(this.search);
  }

  /**
   * Очистить поле поиска
   */
  clearSearch() {
    this.baseFilter.formModelService.clearField('search');
    this.search = '';
    this.baseFilter.onSearchEmit();
    this.isHideCloseBtn = false;
  }

  /**
   * Показать/скрыть расширенный фильтр
   */
  showFilter() {
    this.baseFilter.showFilter();
    this.search = this.baseFilter.formModelService.getValue('search');
  }

  /**
   * Признак отображения большого поля поиска
   */
  isBigSearch() {
    return this.getSelected() !== '' || this.search !== '' || this.isFocus || this.baseFilter?.hasFilterActiveEnable;
  }

  /**
   * Сброс расширенного поиска
   */
  clearFilter() {
    this.baseFilter.resetFilterEmit();
  }
}

export type GetSelectedFn = () => string;
