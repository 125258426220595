import {Injectable} from "@angular/core";
import {UntypedFormGroup} from '@angular/forms';

@Injectable()
export class FormModelService {

  constructor(public form: UntypedFormGroup) {}

  private isValidControl(field: string) {
    return this.form.get(field) !== null;
  }

  getValue(field: string): any {
    if (this.isValidControl(field)) {
      return this.form.get(field).value;
    }
  }

  setValue(field: string, value: any): void {
    if (this.isValidControl(field)) {
      this.form.get(field).setValue(value);
    }
  }

  clearField(field: string): void {
    this.form.get(field).setValue('');
  }

  clearFields(list: string[]): void {
    list.forEach((key: string) => {
      this.form.get(key).setValue(null);
    });
  }

  lockFields(disableList: string[]): void {
    disableList.forEach(field => {
      this.form.get(field).disable();
    });
  }

  unlockFields(enableList: string[]): void {
    enableList.forEach(field => {
      if (this.isValidControl(field)) {
        this.form.get(field).enable();
      }
    });
  }

  lockForm(): void {
    this.form.disable();
  }

  unlockForm(): void {
    this.form.enable();
  }
}
