import {Component, ContentChild, TemplateRef} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TableController} from "@amlCore/tableController";
import {ICustomSelectMapper} from "../../customSelect";
import {TableService, VisitedService} from "@amlCore/services";
import {IErrorFLK} from "../../../interfaces";
import {ErrorFLKItemType, ErrorFLKModel} from "@amlCore/models";

@Component({
  selector: 'app-big-select-view',
  styleUrls: ['./bigSelectView.component.css'],
  templateUrl: './bigSelectView.component.html',
})
export class BigSelectViewComponent extends TableController implements IErrorFLK {

  isReadOnly;
  titlePanel;
  // Выбранные элементы
  selectedItems: Array<any> = [];
  // Элементы в поиске
  searchItems: Array<any> = [];
  // Имя переменой из объека, которая выступает в качесве name
  name;
  // Имя переменой из объека, которая выступает в качесве code
  code;
  getDescriptor: ICustomSelectMapper;
  fieldsFromSearch: string | string[];
  needSearch;
  @ContentChild('labelTemplate', {static: true}) labelTemplate: TemplateRef<any>;
  @ContentChild('optionTemplate', {static: true}) optionTemplate: TemplateRef<any>;
  @ContentChild('labelTemplateInWindow', {static: true}) labelTemplateInWindow: TemplateRef<any>;
  @ContentChild('optionTemplateInWindow', {static: true}) optionTemplateInWindow: TemplateRef<any>;
  isShowVisited = false;
  isSetFocus = false;
  isMultiple: boolean;


  localed = true;
  dirty = false;
  errorFLKData: ErrorFLKItemType;
  public errorFLKList: Array<ErrorFLKModel> = [];

  constructor(private activeModal: NgbActiveModal,
              protected visitedService: VisitedService,
              protected tableService: TableService) {
    super(visitedService, tableService, {pageSize: 20, sort: 'name', dir: 'asc'});
  }

  /**
   * Инициализация данных для отобрадения
   */
  open(param: IBigSelectItem): void {
    this.selectedItems = [];
    this.titlePanel = param.titlePanel;
    this.needSearch = param.needSearch;
    this.isReadOnly = param.isReadOnly;
    this.name = param.name;
    this.code = param.code;
    this.getDescriptor = param.getDescriptor;
    this.fieldsFromSearch = param.fieldsFromSearch;
    this.searchItems = param.items;
    this.labelTemplate = param.labelTemplate;
    this.optionTemplate = param.optionTemplate;
    this.labelTemplateInWindow = param.labelTemplateInWindow;
    this.optionTemplateInWindow = param.optionTemplateInWindow;
    this.isMultiple = param.isMultiple;
    this.errorFLKData = param.errorFLKData;
    this.errorFLKList = param.errorFLKList;
    this.selectedItems = this.initSelectedItems(param.selected);
  }

  /**
   * Ищем выбранные элементы в списке и добавляем их в список с выбранными
   */
  initSelectedItems(items: any): any {
    const result = [];
    if (!items) {
      return result;
    }

    if (this.isMultiple) {
      items.forEach(selectItem => {
        if (this._findSearchItems(selectItem)) {
          result.push(this._findSearchItems(selectItem));
        }
      });
    } else if (!this.isMultiple && this._findSearchItems(items)) {
      result.push(this._findSearchItems(items));
    }

    return result;
  }

  private _findSearchItems(selectItem: any): any {
    const item = this.searchItems.find(searchItem => {
      return this.compareItems(searchItem, selectItem);
    });
    if (!item) {
      console.error('В общем справочнике не найден элемент: ', selectItem);
      return;
    }
    item.check = true;
    return item;
  }
  /**
   * Список с возможностью поиска по name или своим полям из fieldsFromSearch
   * @param search - строка поиска
   */
  list(search?) {
    let result = this.searchItems;
    if (search) {
      const uppCase = search.toUpperCase();
      result = result.filter(data => {
        if (this.fieldsFromSearch) {
          return this.searchBySearchField(uppCase, data);
        } else {
          return data.name.toUpperCase().indexOf(uppCase) > -1;
        }
      });
    }
    this.total = result.length;
    return result;
  }

  /**
   * Поиск по своим полям
   */
  searchBySearchField(term, item) {
    let result = false;
    if (this.fieldsFromSearch instanceof Array) {
      this.fieldsFromSearch.forEach(searchField => {
        if (!result) {
          result = item[searchField].toUpperCase().indexOf(term) > -1;
        }
      });
    } else {
      result = item[this.fieldsFromSearch].toUpperCase().indexOf(term) > -1;
    }
    return result;
  }

  /**
   * Назад без сохранения
   */
  goBack() {
    this.activeModal.close(null);
  }


  /**
   * Удалить элемент из выбранных
   */
  unselect(item: any) {
    this.dirty = true;
    if (this.isReadOnly) {
      return;
    }
    this.searchItems.filter(element => {
      if (element === item) {
        element.check = false;
      }
    });
    this.selectedItems.filter((element, index) => {
      if (element === item) {
        this.selectedItems.splice(index, 1);
      }
    });
  }

  /**
   * Выбрать элемент из списка
   */
  select(item: any): void {
    this.dirty = true;
    this.searchItems.filter((element, index) => {
      if (element === item) {
        const oldCheck = this.searchItems[index].check;
        this.searchItems[index].check = !oldCheck;
        if (!oldCheck && !this._checkSelectedItem()) {
          this.selectedItems.push(item);
        } else {
          this.unselect(item);
        }
      }
    });
  }

  /**
   * Если отключили мультивыбор и выбран 1 элемент
   * @private
   */
  private _checkSelectedItem(): boolean {
    return !this.isMultiple && this.selectedItems.length === 1;
  }

  /**
   * Назад с сохранением
   */
  save(): void {
    this.activeModal.close(
      {selected: this.getItemFromSave(this.selectedItems)} as IBigSelectItem
    );
  }

  /**
   * Получить элементы для сохранения
   */
  getItemFromSave(items: Array<any>) {
    const result = [];
    items.forEach(item => {
      result.push(this.getNewObj(item));
    });
    return result;
  }

  /**
   * преобразуем в формат:
   * {name:value1, code:value2}
   * или по описанию mapObj
   * @param origin - полный объект
   */
  private getNewObj(origin) {
    let newObj: any;
    newObj = {
      name: origin[this.name],
      code: origin[this.code]
    };
    if (this.getDescriptor) {
      newObj = this.getDescriptor.toModel(origin, true);
    }
    return newObj;
  }

  /**
   * Сравниваем два объекта
   * * Необходимо преобразовать searchItem в вид selectItem
   * @param searchItem - объект из поиска
   * @param selectItem - объект из списка выбранных
   */
  compareItems(searchItem, selectItem): boolean {
    const item = this.getNewObj(searchItem);
    const selectItemSearch =  this.getNewObj(selectItem);
    return JSON.stringify(item, Object.keys(item).sort()) === JSON.stringify(selectItemSearch, Object.keys(selectItemSearch).sort());
  }

  onPageChange(params: any): void {
  }
}

/**
 * Интерфес для работы BigSelect комопонента
 */
export interface IBigSelectItem {
  titlePanel: string; // Заголовок окна
  isReadOnly?: boolean; // Форма просмотра
  items: Array<any>; // Список всех элементов (справочник)
  selected: Array<any> | any; // Выбранные элементы
  name: string; // Имя переменой из объека, которая выступает в качесве name
  code: string; // Имя переменой из объека, которая выступает в качесве code
  getDescriptor: ICustomSelectMapper; // Описание для вывода полей
  fieldsFromSearch: string | Array<string>; // Ключи по котором искать в поиске
  needSearch: boolean; // Нужно ли поле поиска
  labelTemplate: TemplateRef<any>; // Шаблон для отображения выбранных элементов и элементов в полей
  optionTemplate: TemplateRef<any>; // Шаблон для отображения элементов в области поиска
  labelTemplateInWindow?: TemplateRef<any>; // Кастомный шаблон для отображения выбранных элементов и элементов в полей
  optionTemplateInWindow?: TemplateRef<any>; // Кастомный шаблон для отображения элементов в области поиска
  errorFLKData: ErrorFLKItemType;
  errorFLKList: Array<ErrorFLKModel>;
  isMultiple?: boolean;
}
