import {Injectable, EventEmitter} from "@angular/core";
import {ApiService} from "./api.service";

@Injectable()
export class ProfileService {
  public userRoleDesc$: EventEmitter<string> = new EventEmitter();
  constructor(private api: ApiService) {
  }
  
  /*
   * получение информации о пользователе
   */
  getUserInfo() {
    return this.api.get('/api/v1/authad/profile/user');
  }
}
