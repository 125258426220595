import {CryptCertInfo} from "./CryptCertInfo";

export interface CryptCertObject {
    isLocal: boolean;
    certObject: any;
    base64Value: string;
    thumb: string;
    certInfo: CryptCertInfo;
    isActual: boolean;
    certCAPICOM: any; // спец объект сертификата со всем функция CAPICOM
    hasPrivateKey: boolean;
    subjectName: string;
    validFrom: any;
    validTo: any;
}
