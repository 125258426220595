import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {UserService} from "./user.service";

@Injectable()
export class AccessRouteGuard  {
  constructor(private router: Router,
              private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.accessList.pipe(take(1), map(data => {
      const access = route.data.access;
      if (!access) {
        return true;
      }
      const hasAccess = this.userService.checkAccess(access);
      if (!hasAccess) {
        this.userService.clearAuth(true);
      }
      return hasAccess;
    }));
  }
}
