import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Location } from '@angular/common';
import {Injectable} from "@angular/core";
import {ConfirmComponent} from "@amlCore/modals";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {JwtService} from "./jwt.service";

/**
 * Гуард проверки выхода из компонента c окном подтверждения
 */
export interface ConfirmDeactivate {
  needConfirmDeactivate: () =>  boolean;
  deactivateConfirmText?: () =>  string;
}

@Injectable()
export class ConfirmDeactivateGuard  {
  constructor(private modalService: NgbModal,
              private readonly router: Router,
              private readonly location: Location,
              private readonly jwtService: JwtService) {
  }

  async canDeactivate(component: ConfirmDeactivate,
                currentRoute: ActivatedRouteSnapshot) : Promise<boolean>{
    if (component?.needConfirmDeactivate() && this.jwtService.getToken()) {
      const modal = this.modalService.open(ConfirmComponent);
      modal.componentInstance.text = component.deactivateConfirmText ? component.deactivateConfirmText()
        : "Вы точно хотите выйти? Не все данные сохранены!";
      modal.componentInstance.isOutputText = false;
      return await modal.result.then(() =>  {
        return true;
      }, () => {

        //обход бага Angular https://github.com/angular/angular/issues/13586#issuecomment-402250031
        const currentUrlTree = this.router.createUrlTree([], currentRoute);
        const currentUrl = currentUrlTree.toString();
        this.location.go(currentUrl);

        return false;
      });
    } else {
      return true;
    }
  }
}