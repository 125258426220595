// Типы панелей по типу ngb-alert type
export type AlertObjType = 'primary' | 'success' | 'danger' ;

export interface IAlertObj {
  // Тип панели
  type: AlertObjType;
  // Время до закрытия
  timeToClose: number;
  // Можно ли скрывать панель
  canClose: boolean;
  // Сообщение панели
  message: string;
}
