import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Alert, AlertType} from './alert.model';
import {AlertService} from './alert.service';
import {faCrosshairs} from "@fortawesome/free-solid-svg-icons";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({selector: 'app-alert', templateUrl: 'alert.component.html'})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id: string;
  alerts: Alert[] = [];
  subscription: Subscription;
  point = this.alertService.point;
  icons = {
    crosshairs: faCrosshairs
  };
  alertToShow: Alert; // Объект с выбранной ошибкой для показа в окне

  constructor(private alertService: AlertService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.subscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {
        if (!alert.message && !alert.messageObject) {
          this.alerts = [];
          return;
        }
        this.autoClose(alert);
        this.addAlert(alert)
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  removeAlert(alert: Alert): void {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: Alert): string {
    const alertClasses = {
      [AlertType.Success]: 'alert alert-success',
      [AlertType.Error]: 'alert alert-danger',
      [AlertType.Info]: 'alert alert-info',
      [AlertType.Warning]: 'alert alert-warning',
      [AlertType.Primary]: 'alert alert-primary',
    };

    return alertClasses[alert.type] || '';

  }

  /**
   * Отображение доп полей ошибки в модальном окне
   * @param alert - ошибка
   * @param content - окно
   */
  showAll(alert: Alert, content): void {
    if(alert.errorText){
      this.alertToShow = alert;
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
    }
  }

  autoClose(alert: Alert): void {
    if (alert.closeTimer) {
      setTimeout(() => {
        this.removeAlert(alert);
      }, alert.closeTimer)
    }
  }

  /**
   * Исправление ошибки, когда может вызываться большое количество алертов с одинаковым сообщением
   */
  private addAlert(alert: Alert) {
    if (!this.alerts.find((item) => item?.message === alert?.message)) {
      this.alerts.push(alert)
    }
  }
}
