// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-ip {
    margin-top: 5px;
    padding: 5px;
    font-size: 14px;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/core/filterComponents/auditFilter/auditFilter.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".error-ip {\n    margin-top: 5px;\n    padding: 5px;\n    font-size: 14px;\n    border: none;\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 1.5;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
