import {Component, OnInit, Input} from "@angular/core";
import {BaseFilterController} from "../BaseFilterController";
import {UntypedFormBuilder} from "@angular/forms";
import {TableService} from "@amlCore/services";

/**
 * Реализация шаблона перенесена в app-search-header
 * Необходимо указывать app-search-input-filter для связи компонента таблицы и фильтра в хеадере
 */
@Component({
  selector: "app-search-input-filter",
  templateUrl: "./searchInputFilter.component.html"
})
export class SearchInputFilterComponent extends BaseFilterController implements OnInit {

  @Input() placeholderStuff: string;
  placeholder = '';
  filterButtonShow = false;

  constructor(private fb: UntypedFormBuilder, protected tableSrv: TableService) {
    super(tableSrv);
  }

  public filterObjectModel = {search: ''};

  ngOnInit() {
    this.placeholder = this.placeholderStuff;
    this.init();
  }

  public createFormModel() {
    return this.fb.group({search: ''});
  }

  getStartFilterModel(): any {
    return null;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return '';
  }
}
