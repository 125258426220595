export interface CryptVersionValidate {
    isPluginLoaded: boolean; // произошла загрузка
    isPluginWorked: boolean;  // полностю рабочий
    isPluginEnabled: boolean; // плагин может быть заблокирован браузером или системой безопасности
    isActualVersion: boolean; // актуальная версия
    currentPluginVersion: string; // версия установленного плагина
    cryptoCSPName: string; // наименование криптопровайдера
    cryptoCSPVersion: string; // версия криптопровайдера
    typeError: string | 'error' | 'warning'; // вид ошибки может быть просто предупреждение что надо повысить версию
    textError: string; // текстовка ошибки
}
