/**
 * Интерфейс описания Page страницы
 * где content список определенного типа
 */
export interface PageContentModel<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: any;
  size: number;
  sort: any;
  totalElements: number;
  totalPages: number;
}
