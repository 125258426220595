import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Utils} from "@amlCore/utils";
import {ControlContainer, UntypedFormGroup, FormGroupDirective} from "@angular/forms";
import {UserService} from "@amlCore/services";
import {Role, User} from "@amlCore/models";

@Component({
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  selector: 'app-new-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['../../../../monitor.scss']
})
export class UserFormComponent implements OnInit, OnDestroy {

  goBack = Utils.goBack
  formUserControl: UntypedFormGroup;
  @Input() roles: Role[];
  @Input() submitted: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() user: User
  successChangePass = null;
  
  @Output() submitForm = new EventEmitter()
  
  constructor(private readonly userService: UserService, private parent: FormGroupDirective) { }

  ngOnInit(): void {
    this.initFormControl()
    // AML-2546 - Решение временное - потом убрать вовсе
    document.body.style.backgroundColor = '#FFFFFF'
  }

  get f() {
    return this.formUserControl.controls;
  }

  generatePassword(): void {
    this.userService.generateTempPassUser(this.f?.id?.value).subscribe(() => this.successChangePass = true);
  }

  initFormControl(): void {
    this.formUserControl = this.parent.form;
    if (this.isReadOnly){
      this.formUserControl.disable();
    }
  }

  ngOnDestroy(): void {
    // AML-2546 - Решение временное - потом убрать вовсе
    document.body.style.backgroundColor = '#EEEEEE'
  }
}
