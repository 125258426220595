export class Alert {
  type: AlertType;
  message: string;
  errorText: string;
  messageObject: Array<object>;
  alertId: string;
  keepAfterRouteChange: boolean;
  closeTimer: number; // кол-во мсек до закрытия алерта

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning,
  Primary
}
