// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Скрываем инпут для датапикера - удалять из дом нельзя, тк вспылвающее окно привязано к позии  этого инпута*/
.aml-datepicker .input-hidden {
  visibility: hidden;
  width: 0
}
/* Для отображаемого интупа - делаем его как будто он левый элемент, тк нельзя удалять скрытый инпут*/
.aml-datepicker .input-radius {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;;
  margin-left: -4px;
}

.aml-datepicker-footer {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/core/components/datepicker/datepicker.component.css"],"names":[],"mappings":"AAAA,8GAA8G;AAC9G;EACE,kBAAkB;EAClB;AACF;AACA,qGAAqG;AACrG;EACE,0CAA0C;EAC1C,6CAA6C;EAC7C,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":["/* Скрываем инпут для датапикера - удалять из дом нельзя, тк вспылвающее окно привязано к позии  этого инпута*/\n.aml-datepicker .input-hidden {\n  visibility: hidden;\n  width: 0\n}\n/* Для отображаемого интупа - делаем его как будто он левый элемент, тк нельзя удалять скрытый инпут*/\n.aml-datepicker .input-radius {\n  border-top-left-radius: 0.25rem !important;\n  border-bottom-left-radius: 0.25rem !important;;\n  margin-left: -4px;\n}\n\n.aml-datepicker-footer {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
