/**
 * Модель ошибок по ФЛК
 */
export interface ErrorFLKModel {
  path: string; // Путь до ошибки
  msg?: string; // Сообщение об ошибке (краткое)
  reason?: string; // Сообщение об ошибке (подробное)
  row?: number; // при наличии items для табличных элементов дублируется ключ в row (для работы подкомпонентов)
  items?: ErrorFLKItemType; // Ошибки по таблице
}
export type ErrorFLKItemType = { [key: string]: Array<ErrorFLKModel> };
