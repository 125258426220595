import {EventEmitter, Output, Input, OnDestroy, Directive} from "@angular/core";
import {faTimesCircle, faSearch, faFilter} from "@fortawesome/free-solid-svg-icons";
import {UntypedFormGroup} from '@angular/forms';
import {Unsubscribable} from 'rxjs';
import {FormModelService, StoreService, TableService} from '../services';
import {ClientTypeEnum} from '../enum';
import {ActivatedRoute} from "@angular/router";
import moment from 'moment';

@Directive()
export abstract class BaseFilterController implements OnDestroy {

  @Output() search: EventEmitter<any> = new EventEmitter(); // событие простого поиска
  @Output() filterActive: EventEmitter<any> = new EventEmitter(); // событие расширенного поиска
  @Output() filter: EventEmitter<any> = new EventEmitter(); // событие сохранения текущих параметров фильтра
  @Output() switchResult: EventEmitter<any> = new EventEmitter(); // событие переключения поля все/требующие решения
  @Output() resetFilterActive: EventEmitter<object> = new EventEmitter(); // событие сброса расширенного поиска в начальное состояние
  @Input() urlData: object; // урл из строки браузера.
  @Input() resultKey: string; // уникальный ключ для чтения значения из LocalStorage
  @Input() filterButtonShow = true; // Признак отображения кнопки расширенного фильтра
  @Input() disabledSearchInput = false; // Признак блокировки простого поиска
  public isClearFilter = true; // Признак очищеного расширенного фильтра
  public formModel: UntypedFormGroup;
  public formModelService: FormModelService; // сервис для работы с формой
  public hasFilterActiveEnable = false; // флаг. показать или скрыть форму расширенного фильтра
  public switchTooltip = "Все";
  public abstract filterObjectModel: object; // объект фильтра для установки расширенного поиска в начальное состояние
  public placeholder = '';
  public filterDescription = '';
  public errorArray = {};
  public createOperationErrorMsg = 'Дата начала создания, не может быть позже Даты завершения создания операции';
  public transactionDateErrorMsg = 'Дата начала совершения операции не может быть позже даты завершения совершения операции';
  public sumCurErrorMsg = 'Сумма операции в валюте По не может быть меньше суммы операции в валюте От';
  public sumRubErrorMsg = 'Сумма операции в рублях По не может быть меньше суммы операции в рублях От';
  public docDateErrorMsg = 'Дата документа По не может быть меньше даты документа С';
  public checkPeriodErrorMsg = 'Дата начала проверки периода не может быть больше Даты окончания проверки периода';
  public noticePeriodErrorMsg = 'Дата начала события, не может быть позже Даты завершения события';
  public icons = {
    filter: faFilter,
    search: faSearch,
    circle: faTimesCircle,
  };
  private clientTypeEnum = ClientTypeEnum;
  protected storeService = new StoreService();
  private formModelSub: Unsubscribable;
  private _activeFilterStateKey = 'page';
  public disabledSimpleFilter = false;
  public maxDateFix = '01.01.2100'
  pageName = '';
  customDescFields: {
    fieldName: string,
    customDescription: () => string
  }[] = []; //

  constructor(protected tableSrv: TableService, private _activatedRoute?: ActivatedRoute) {
  }

  /**
   * Общий метод инициализации
   * 1. Сборка формы
   * 2. Иницализация сервиса по работе с формой
   * 3. Подписка на изменения в модели формы
   * 4. Установка состояния полей фильтра в зависимости от того
   *    расширенный ли это поиск или нет
   * 5. Установка данных из url в форму
   * 6. установка режима отображения расширенного или простого поиска. развернут.свернут
   */
  public init() {
    this._activatedRoute?.snapshot.url.forEach((segment) => {
      this.pageName += segment.path;
    });
    this._checkActiveFilterKeyInStore();
    this.formModel = this.createFormModel();
    this.initFormModleService();
    this.subscribeFormModel();
    this.fetchFormModelFromUrl(this.urlData);
    if (this.hasStateActiveFilter(this.urlData)) {
      this.showFilter();
      this.filterDescription = this.initFilterDescription();
      this.isClearFilter = false;
    } else {
      this.setFilterFieldsState();
      const startFilter = this.getStartFilterModel();
      if (startFilter) {
        this.formModel.patchValue(startFilter);
      }
    }
    setTimeout(() => this.tableSrv.setBaseFilter(this));
  }

  /**
   * Общий метод сброса фильтра расширенного поиска
   */
  public resetFilterEmit() {
    this.isClearFilter = true;
    this.formModel.patchValue(this.filterObjectModel);
    this.filterDescription = this.initFilterDescription();
    this.resetFilterActive.emit();
    this.setFilterFieldsState();

    if (this.pageName === 'dossierlist') {
      this.formModelService.form.controls.subject.patchValue({typePart:'svedClient'});
    }
  }

  /**
   * Метод определения по url включен ли режим активного фильтра
   * @param urlData url объект с данными фильтра
   */
  protected hasStateActiveFilter(urlData: object) {
    const baseParams = ["page", "size", "sort", "dir"];
    const hasActiveParams = Object.keys(urlData || [])
                            .filter((param) => !baseParams.includes(param)).length > 0;
    return !urlData?.hasOwnProperty("search") && hasActiveParams && this.storeService.get(this._activeFilterStateKey);
  }

  /**
   * Метод установки состояния полей фильтра
   * в зависимости от флага hasFilterActiveEnable
   * режима расширенный фильтр
   */
  public setFilterFieldsState() {
    if (this.isClearFilter && !this.hasFilterActiveEnable) {
      this.formModelService.lockForm();
      this.formModelService.unlockFields(["search", "result"]);
    } else {
      this.formModelService.unlockForm();
      this.formModelService.lockFields(["search"]);
    }

    this.syncResultsSwitchs();
  }

  /**
   * Метод установки начального значения фильтра
   * Устанавливается только при первом открытии (НЕ устанавливается по кнопке очистить поиск!)
   */
  abstract getStartFilterModel();

  /**
   * Метод синхронизирующий значенения
   * между двумя переключателями (все/требующие решения),
   * находящимся в простом и расширенном поиске
   */
  public syncResultsSwitchs() {
    const value: boolean = this.storeService.get(this.resultKey);
    this.formModelService.setValue("result", value);
  }

  /**
   * Метод вычисления показывать или нет разметку для ИП
   */
  public isVisiblePersonEntity(): boolean {
    return (this.formModelService.getValue('subjectType') === this.clientTypeEnum.INDIVIDUAL_PERSON ||
            this.formModelService.getValue('subjectType') === this.clientTypeEnum.INDIVIDUAL_ENTREPRENEUR);
  }

  /**
   * Метод вычисления показывать или нет разметку для ЮЛ
   */
  public isVisibleLegalEntity(): boolean {
    return this.formModelService.getValue('subjectType') === this.clientTypeEnum.LEGAL_ENTITY;
  }

  /**
   * метод проверки в ls, наличия ключа признака
   * открытого или свернутого расширенного фильтра
  */
  private _checkActiveFilterKeyInStore(): void {
      // проверяем есть ли в сторе сохраненное состояние признака свернутого или развернутого фильтра
      if (this._activatedRoute &&
        this._activatedRoute.snapshot &&
        this._activatedRoute.snapshot.data &&
        this._activatedRoute.snapshot.data.hasOwnProperty('typePage')) {
          this._activeFilterStateKey = `${this._activatedRoute.snapshot.data.typePage}HasEnableActiveFilter`;
          // если ключа нет то ставим по умолчанию расширенный фильтр в свернутом состоянии
          if (this.storeService.get(this._activeFilterStateKey) === null) {
            this.storeService.save(this._activeFilterStateKey, false);
          }
        }
  }

  /**
   * Метод установки данных в форму из url
   * @param data данные из url
   */
  private fetchFormModelFromUrl(data) {
    if (data !== undefined) {
      this.formModel.patchValue(data);
    }
  }

  /**
   * Метод инициализация сервиса по работе с формой
   */
  private initFormModleService() {
    this.formModelService = new FormModelService(this.formModel);
  }

  /**
   * метод подписки формы на valueChanges
   */
  public subscribeFormModel() {
    this.formModelSub = this.formModel.valueChanges.subscribe((data) => {
      this.filter.emit(data);
    });
  }

  /**
   * Метод переключения признака - режим активного фильтра
   * а так же при переключении устанавливает состояние полей
   * фильтра disable / enable
   */
  public showFilter() {
    this.hasFilterActiveEnable = !this.hasFilterActiveEnable;
    this.storeService.save(this._activeFilterStateKey, this.hasFilterActiveEnable);
    this.setFilterFieldsState();
  }

  /**
   * Метод переключателя все/требующие решения
   * передает событие о переключении
   */
  public switchResultEmit(value: boolean) {
    this.switchResult.emit(value);
  }

  /**
   * Метод получения tooltip
   * для переключателя все/требующие-решения
   */
  public getTooltipSwitchResult(): string {
    return this.formModelService.getValue("result") ? "Требующие решения" : "Все";
  }

  /**
   * Метод получения подписи для label
   * переключателя все/требующие-решения
   */
  public getLabelSwitchResult(): string {
    return this.formModelService.getValue("result") ? "Требующие решения" : "Все";
  }

  /**
   * Метод простого поиска
   * сообщает TableController о том
   * что необходимо произвести поиск
   * по полю search
   */
  public onSearchEmit(value?: string) {
    if (value) {
      this.formModelService.setValue('search', value);
    }
    this.search.emit(null);
    this.filterDescription = this.initFilterDescription();
  }

  /**
   * Метод расширенного поиска
   * сообщает TableController о том
   * что необходимо произвести поиск
   * по полям расширенного фильтра
   */
  public onFilterEmit(isOpen?: boolean) {
    this.isClearFilter = false;
    this.formModelService.clearField('search');
    this.filterActive.emit(null);
    this.filterDescription = this.initFilterDescription();
  }

  /**
   * Метод получения описания полей для SearchHeaderComponent.
   * Так же можно задать в дочернем классе массив this.customDescFields и указать для определенных
   * полей свой метод получения описания!
   */
  private initFilterDescription(): string {
    if (this.hasFilterActiveEnable) {
      const val = this.formModel.value;
      const desc = this.getFilterDescription();
      let text = '';
      getDescription.call(this, val, desc);
      return text;

      function getDescription(values, description) {
        Object.keys(values).forEach(key => {
          const field = values[key];
          if (field || field === 0) {
            if ((typeof field === 'string' || typeof field === 'number') && field !== '') {
              addText(description[key]);
            }

            const findCustomField = this.customDescFields.find(e => e.fieldName === key);
            if (this.customDescFields.length && findCustomField) {
              addText(findCustomField.customDescription())
              return;
            }

            if (typeof field === 'object') {
              getDescription.call(this, field, description[key]);
            }
          }
        });
      }

      function addText(newText) {
        if (newText !== '' && text !== '') {
          text += ', ' + newText;
        } else {
          text += newText;
        }
      }
    } else {
      return '';
    }
  }

  public getFilterToStr(): string {
    return this.filterDescription;
  }

  f(field: string) {
    return this.formModel.get(field);
  }

  ngOnDestroy() {
    this.formModelSub.unsubscribe();
    // Сброс установленного фильтра
    this.tableSrv.setBaseFilter(null);
    this.disabledSimpleFilter = false;
  }

  /**
   * Метод описания полей фильтра по модели
   * поля которые не нужно отображать в поле необходимо задать как ''
   */
  public abstract getFilterDescription(): any;

  public abstract createFormModel(); // метод создания формы


  /**
   * compareDateFromTo(from: string, to: string, format: string, errorType: string, errorMsg: string )
   * Метод сравнивает два значения ДАТ - from - начальное и to - конечное (С и ПО);
   * format - формат сравнения (дата = date, датаВремя = dateTime, просто числа = '');
   * errorType - назначение проверяемого поля;
   * errorMsg - выводимое сообщение;
   */
  compareDateFromTo(from: string, to: string, errorType: string, errorMsg: string ) {
    delete this.errorArray[errorType];

    const fromValue = this.formModelService.getValue(from);
    const toValue = this.formModelService.getValue(to);

    if (fromValue && toValue && moment(fromValue, "DD.MM.YYYY HH.mm.ss").isAfter(moment(toValue, "DD.MM.YYYY HH.mm.ss"))) {
      this.errorArray[errorType] = errorMsg;
    }
  }

  /**
   * compareFromTo(from: string, to: string, errorType: string, errorMsg: string )
   * Метод сравнивает два значения - from - начальное и to - конечное (С и ПО);
   * errorType - назначение проверяемого поля;
   * errorMsg - выводимое сообщение;
   */
  compareFromTo(from: string, to: string, errorType: string, errorMsg: string ) {
    delete this.errorArray[errorType];

    const fromValue = this.formModelService.getValue(from);
    const toValue = this.formModelService.getValue(to);

    if (fromValue && toValue &&  fromValue > toValue) {
      this.errorArray[errorType] = errorMsg;
    }
  }
}
