import {Component, HostListener, OnInit} from '@angular/core';
import {UserService} from './core/services';
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import { faInfoCircle as fasInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'aml-comita';

  constructor(private userService: UserService, library: FaIconLibrary) {
    library.addIcons(fasInfoCircle);
  }

  ngOnInit() {
    document.getElementById('spin_start-page_load_aml').style.visibility = "hidden";
    this.userService.tryAuth();
  }
  
  // Временное решение для обновления UI. Пока что футер будет только для админ версии, далее это убрать
  // и сделать футер общим
  isAdmin() {
    return sessionStorage.getItem('userRole') === 'admin'
  }
  
  
  @HostListener('click')
  @HostListener('keydown')
  @HostListener('mouseleave')
  @HostListener('blur')
  @HostListener('focus')
  @HostListener('window:scroll')
  @HostListener('window:resize')
  onClick() {
    this.userService.userActions$.next('user action')
  }
}
