// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.big-select-item {
  padding: 5px;
  margin: 3px 3px;
  display: inline-block;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 1px solid #ced4da;
  cursor: pointer;
}
.big-select-item:hover {
  background: #f3f3f3;
}

.cancel {
  color: #767879;
}

.selected {
  color: #28a745;
}

`, "",{"version":3,"sources":["webpack://./src/app/core/components/bigSelect/bigSelectView/bigSelectView.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,gBAAgB;EAChB,qBAAqB;EACrB,yBAAyB;EACzB,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".big-select-item {\n  padding: 5px;\n  margin: 3px 3px;\n  display: inline-block;\n  line-height: 1.5;\n  border-radius: 0.2rem;\n  border: 1px solid #ced4da;\n  cursor: pointer;\n}\n.big-select-item:hover {\n  background: #f3f3f3;\n}\n\n.cancel {\n  color: #767879;\n}\n\n.selected {\n  color: #28a745;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
