import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BaseModule} from './base';
import {CoreModule, VisitedInterceptor, AuthInterceptor, BearerInterceptor} from './core';
import {FooterComponent, HeaderComponent} from './base/layout';
import {NgSelectConfig} from "@ng-select/ng-select";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localeRu from '@angular/common/locales/ru';
import {CommonModule, registerLocaleData} from "@angular/common";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {AuthModule} from "./auth";
import {DefaultValueAccessor} from "@angular/forms";
import {NgxMaskDirective, provideEnvironmentNgxMask} from "ngx-mask";
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";

registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [
    AppComponent, FooterComponent, HeaderComponent
  ],
  imports: [
    CommonModule,
    LoadingBarHttpClientModule,
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    BaseModule,
    CoreModule.forRoot(),
    NgbModule,
    BrowserAnimationsModule,
    NgxMaskDirective
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: VisitedInterceptor,
    multi: true
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'ru'},
    provideEnvironmentNgxMask()],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private config: NgSelectConfig, library: FaIconLibrary) {
    this.config.notFoundText = 'Ничего не найдено';

    // fix - Reactive forms cleared input becomes empty string instead of null
    // https://github.com/angular/angular/issues/45317
    // После ручной очистки поля на сервер отправляется пустая строка, а не null,
    // которая может не проходить проверку по аннотации @Pattern, даже если поле отмечено как необязательное
    DefaultValueAccessor.prototype.registerOnChange = function (fn: (_: string | null) => void): void {
      this.onChange = (value: string | null) => {
        fn(value === '' ? null : value);
      };
    };
  }
}
