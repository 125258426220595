// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.main {
  display: flex;
}

.menu-unit-title {
  cursor: pointer;
  margin-left: 17px;
}

.nav-scroll {
  height: calc(100vh - 100px); /* высота нашего блока */
  /* ширина нашего блока */
  overflow-y: scroll; /* прокрутка по вертикали */
  margin: 0px;
}

.dropdown-menu {
  z-index: 1050;
}

.header-menu {
  display: flex;
  justify-content: end;
  width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/app/base/layout/header.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,aAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,2BAAA,EAAA,wBAAA;EACC,wBAAA;EAGD,kBAAA,EAAA,2BAAA;EACA,WAAA;AADF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,aAAA;EACA,oBAAA;EACA,UAAA;AAAF","sourcesContent":["\n.main {\n  display: flex;\n}\n\n.menu-unit-title {\n  cursor: pointer;\n  margin-left: 17px;\n}\n\n.nav-scroll {\n  height: calc(100vh - 100px); /* высота нашего блока */\n   /* ширина нашего блока */\n  //background: rgb(16, 125, 168); /* цвет фона, белый */\n  //border: 1px solid #aa0d0d; /* размер и цвет границы блока */\n  overflow-y: scroll; /* прокрутка по вертикали */\n  margin: 0px;\n}\n.dropdown-menu {\n  z-index: 1050;\n}\n\n.header-menu {\n  display: flex;\n  justify-content: end;\n  width: 70%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
