import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Auth} from '@amlCore/models';
import {UserService} from '@amlCore/services';
import {ActivatedRoute, Router} from '@angular/router';
import {MustMatch} from '@amlCore/validators';
import {AlertService} from "@amlCore/components";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  submittedPass = false;
  form: UntypedFormGroup;
  userAuth: Auth;
  userId: string;
  constructor(private readonly fb: UntypedFormBuilder,
              private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly userSrv: UserService,
              private readonly alertSrv: AlertService) {
  }

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.params.id;
    this.form = this._initForm(this.userId);
  }

  private _initForm(userId: string): UntypedFormGroup {
    return this.fb.group({
      login: [null],
      oldPassword: [{value: null, disabled: !userId}, Validators.required],
      password: [null, Validators.required],
      cPass: [null, Validators.required],
    }, {
      validator: MustMatch('password', 'cPass')
    });
  }

  changePass(): void {
    this.userAuth = this.userSrv.getAuthenticateUser;
    this.submittedPass = true;
    if (this.form.invalid) {
      return;
    }
    this.submittedPass = false;
    this.userSrv.changePassword(
      {
        oldPassword: this.form.get('oldPassword').value,
        password: this.form.get('password').value,
        login: this.userAuth.login
      }).subscribe(() => {
      const credentials = {
        login: this.userAuth.login,
        password: this.form.get('password').value,
      };
      this.userSrv
        .attemptAuth(credentials, 'caml')
        .subscribe(
          (data: any) => {
            if (data.body.needChangePassword) {
              this.router.navigateByUrl('/changePassword');
              return;
            }
            this.userSrv.isExpiredPassword(data.id).subscribe((response: { error: string }) => {
              if (response && response.error) {
                this.alertSrv.error(response.error);
              }
            });
            this.router.navigateByUrl(this.userSrv.getStartPageUser(data.body));
          }
        );
    });
  }

  cancelChangePwd(): void {
    history.back();
  }

}
