import { Component, OnInit, Input, Output, EventEmitter, DoCheck } from '@angular/core';
import { IPaginate } from './../../interfaces/IPaginate';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, DoCheck {

  @Input() total?: any;
  @Input() infinityPagination?: boolean;
  pageValue: any;
  pageSizeValue: any;
  @Output() pageChangePaginator = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter();
  @Output() pageSizeChange = new EventEmitter();
  @Input() pageSizesArr: number[] = [20, 50, 100];
  @Input()
  sticky? = true;

  @Input() pageName: string;
  paginateParamsArr = JSON.parse(localStorage.getItem('paginateParamsArr')) || [];
  @Input()
  get pageSize() {
    return this.pageSizeValue;
  }

  set pageSize(val) {
    this.pageSizeValue = val;
    this.pageSizeChange.emit(this.pageSizeValue);
  }

  @Input()
  get page() {
    return this.pageValue;
  }

  set page(val) {
    this.pageValue = val;
    this.pageChange.emit(this.pageValue);
  }

  constructor() { }

  ngOnInit() {
    setTimeout(() => {this.checkPageSize(); }, 0)  ;
  }

  /**
   * Проверка, если включент флаг infinityPagination = true
   * и элемент пйджинации создан то происходит
   * скрытие кнопок для пейджинга
   * остаются только кнопки следующая страница или предыдущая
   * @returns boolean
   */
  ngDoCheck() {
    if (this.infinityPagination && this.isValidHTMLElement()) {
      this.hidePaginationNumbers();
    }
  }

  onPageChangePaginator(event) {
    this.page = event;
    this.pageChangePaginator.emit(event);
  }

  setPageSize(size: number) {
    this.setPageSizeParams({ page: this.pageName, size});
    this.pageSize = size;
    this.pageValue = 1;
    this.page = 1;
    this.pageChangePaginator.emit();
  }

  /**
   * Обновляет данные локального хранилища для показа кол-ва элементов на странице
   * @param obj входные параметры
   */
  setPageSizeParams(obj: IPaginate) {
    for (let i = 0; i < this.paginateParamsArr.length; i++) {
      if (this.paginateParamsArr[i].page !== undefined && this.paginateParamsArr[i].page === obj.page) {
        localStorage.removeItem('paginateParamsArr');
        this.paginateParamsArr.splice(this.paginateParamsArr.indexOf(this.paginateParamsArr[i]), 1);
      }
    }

    if (obj.hasOwnProperty('page') && obj.page !== undefined) {
      this.paginateParamsArr.push(obj);
      localStorage.setItem('paginateParamsArr', JSON.stringify(this.paginateParamsArr));
    }
  }

  /**
   * Проверяет, было ли сохранено показ кол-ва элементов на странице
   */
  checkPageSize(): void {
    const arr = JSON.parse(localStorage.getItem('paginateParamsArr')) || [];
    if (arr !== undefined && arr.length > 0 ) {
      for (let i = 0; i < this.paginateParamsArr.length; i++) {
        if (this.paginateParamsArr[i].page === this.pageName) {
          this.setPageSize(this.paginateParamsArr[i].size);
        }
      }
    }
  }


  /**
   * Проверка, что компонент пагинации
   * с кнопками для пейджинга создан в DOM структуре
   * @returns boolean
   */
  isValidHTMLElement() {
    return document.querySelector("ngb-pagination .page-link");
  }

  /**
   * Метод последовательно скрывает каждый элемент
   * который содержит цифру или ..., кроме кнопок
   * следующая и предыдущая страница
   */
  hidePaginationNumbers() {
    const nodeList = document.querySelectorAll("ngb-pagination .page-link");
    (nodeList || []).forEach((node) => {
      if (/\d+/g.test(node.innerText) || /^\...$/.test(node.innerText)) {
          node.style.display = "none";
      }
    });
  }

  /**
   * Метод проверки для отображения
   * кнопок двойной пейджинации (>>>>) (<<<<)
   * если флаг infinityPagination = true
   * то кнопки скрываем
   */
  isVisibleBoundaryLinks() {
    if (this.infinityPagination) {
      return false;
    }
    return true;
  }

  /**
   * Метод получения максимального
   * количества кнопок которые будут
   * отображены для пейджинга
   * @returns number
   */
  getMaxSize() {
    const defaultMaxSize = 9;
    if (this.infinityPagination) {
      return 1;
    }
    return defaultMaxSize;
  }

}
