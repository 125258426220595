// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  position: sticky !important;
}

ngb-pagination {
  display: inline-block;
}

.pagination-wrap {
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.page-size-wrap {
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
  margin-left: 5px;
  border: 1px solid #dee2e6;
  padding-left: 0;
}
.page-size-wrap li {
  display: inline-block;
  padding: 0 5px;
  border-left: 1px solid #dee2e6;
  cursor: pointer;
}
.page-size-wrap li:first-child {
  border: none;
}
.page-size-wrap li.active, .page-size-wrap li:hover {
  background-color: #e6e6e6;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/paginator/paginator.component.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;EACA,SAAA;EACA,OAAA;EACA,aAAA;EACA,2BAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,qBAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;AACF;AAAE;EACE,qBAAA;EACA,cAAA;EACA,8BAAA;EACA,eAAA;AAEJ;AADI;EACE,YAAA;AAGN;AADI;EACE,yBAAA;AAGN","sourcesContent":[":host {\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 1030;\n  position: sticky !important;\n}\n\nngb-pagination{\n  display: inline-block;\n}\n\n.pagination-wrap{\n  align-items: center;\n  justify-content: space-between;\n  display: flex;\n  flex-direction: row;\n}\n\n.page-size-wrap{\n  list-style-type: none;\n  display: inline-block;\n  margin-right: 20px;\n  margin-left: 5px;\n  border: 1px solid #dee2e6;\n  padding-left: 0;\n  li{\n    display: inline-block;\n    padding: 0 5px;\n    border-left: 1px solid #dee2e6;\n    cursor: pointer;\n    &:first-child{\n      border: none;\n    }\n    &.active, &:hover{\n      background-color: #e6e6e6;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
