import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BaseRoutingModule} from "./base-routing.module";
import {HomePageComponent} from "./layout/homePage.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {CoreModule} from "../core";
import {SearchHeaderComponent} from "./searchHeader";



@NgModule({ exports: [
        SearchHeaderComponent
    ],
    declarations: [
        HomePageComponent, SearchHeaderComponent
    ], imports: [CoreModule,
        BaseRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        NgSelectModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class BaseModule {
}
