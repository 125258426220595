import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {JwtService} from './jwt.service';
import {catchError} from 'rxjs/operators';

@Injectable({providedIn: "root"})
export class ApiService {
  result: string;

  constructor(private http: HttpClient) {
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  // TODO метод objectSerializeUrl не нужен, это можно сделать средствами Angular (через new HttpParams())
  objectSerializeUrl(data: object, asForm?: boolean): string {
    if (data) {
      this.result = Object.entries(data).map(([key, val]) => `${key}=${val}`).join('&');
    }
    return (asForm ? '' : '?') + this.result;
  }

  get(path: string, params?: any, visitedValue?: string, type?): Observable<any> {
    // TODO метод objectSerializeUrl не нужен, это можно сделать средствами Angular (через new HttpParams())
    const url = (params ? (path + this.objectSerializeUrl(params)) : path);
    const headers = {
      'Content-Type': 'application/json'
    };
    if (visitedValue) {
      headers["visited"] = visitedValue;
    }
    return this.http.get(url, {
      headers: new HttpHeaders(headers),
      ...(type && {responseType: type})
    })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(
      path,
      JSON.stringify(body),
      {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }
        )
      }
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object = {}, visitedValue?: string, type?, observe?: boolean): Observable<any> {
    const headers = {
      'Content-Type': 'application/json'
    };
    if (visitedValue) {
      headers["visited"] = visitedValue;
    }
    return this.http.post(
      path,
      JSON.stringify(body),
      {
        headers: new HttpHeaders(headers),
        ...(type && {responseType: type}),
        ...(observe && {observe: 'response'}),
      },

    ).pipe(catchError(this.formatErrors));
  }
  byte(path: string, body: object = {}): Observable<any> {
    return this.http.post(
      path,
      JSON.stringify(body),
      {
        observe: 'events',
        reportProgress: true,
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }
        ), responseType: 'blob'
      }
    ).pipe(catchError(this.formatErrors));
  }
  byteGet(path: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      path, {observe: 'response', params: params, reportProgress: true, responseType: 'blob'}
    ).pipe(catchError(this.formatErrors));
  }
  form(path: string, body: object = {}): Observable<any> {
    return this.http.post(
      path,
      this.objectSerializeUrl(body),
      {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    );
  }
  sendFile(path: string, body: object = {}): Observable<any> {
    return this.http.post(
      path,
      this.objectSerializeUrl(body, true),
      {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    );
  }

  sendFormData(patch: string, formData, params?: HttpParams): Observable<any> {
    return this.http.post(
      patch,
      formData,{
        params:params
      }
    ).pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.api_url}${path}`
    ).pipe(catchError(this.formatErrors));
  }

  deleteArray(path: string, body: Array<String>): Observable<any> {
      return this.http.request('delete', path, {
        body: body,
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        withCredentials: true
      }).pipe(catchError(this.formatErrors));
  }
}
