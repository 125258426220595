import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../../modals';
import {ICheckBoxValueType} from "./ICheckBoxValueType";

/**
 * Компонент для группы чекбоксов, значения выбранные которых должны склеиваться в одну строку
 */

@Component({
  selector: 'app-joined-multi-checkbox',
  template: `
    <div class="mb-3 row no-gutters" *ngFor="let item of items">
      <div class="col-md-auto pt-1">
        <label class="aml-checkbox">
          <input type="checkbox" (click)="onSelectItem(item, $event)" [checked]="item.checked"/>
          <span class="success"></span>
        </label>
      </div>
      <div class="col">
        <label class="form-label">{{item.code}} - {{item.name}}</label>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JoinedMultiCheckboxComponent),
      multi: true,
    },
  ],
})
export class JoinedMultiCheckboxComponent implements ControlValueAccessor, OnInit{
  @Input() formControl: UntypedFormControl;
  @Input() isReadOnly: boolean;
  @Input() dictItems: ICheckBoxValueType[];
  @Input() separator: string = ',';
  @Output() codeAdded = new EventEmitter<string>();
  @Output() codeDeleted = new EventEmitter<string>();
  onChange = (result: any) => {};
  onTouch = () => {
  }

  constructor(private readonly modalService: NgbModal) {}
  items: ICheckBoxValueType[];

  ngOnInit(){
    this.items = this.dictItems.map(obj => ({...obj}));
  }

  writeValue(value: string) {
    const values = value.split(this.separator);
    this.items.forEach(item => {
      item.checked = values.includes(item.code);
    })
  }

  registerOnChange(callback) {
    this.onChange = callback;
  }

  registerOnTouched(callback) {
    this.onTouch = callback;
  }

  onSelectItem(item: ICheckBoxValueType, event: any) {
    if (event.target.checked){
      item.checked = event.target.checked;
      this.codeAdded.emit(item.code);
    } else {
      event.preventDefault();
      this.beforeUnChecked(item, event.target.checked);
    }
    this.onChange(this.items.filter(item => item.checked).map(obj => obj.code).join(this.separator));
  }


  beforeUnChecked(item: ICheckBoxValueType, cheked?): boolean {
    const modal = this.modalService.open(ConfirmComponent);
    modal.componentInstance.text = "Вы действительно хотите удалить файлы вложений?";
    modal.componentInstance.title = "Предупреждение";
    modal.componentInstance.okBtn = "Да";
    modal.componentInstance.cancelBtn = "Нет";

    modal.result.then(
        (data) => {
          this.codeDeleted.emit(item.code);
          item.checked = cheked;
          let values = this.formControl.value.split(this.separator);
          values = values.filter(v => v !== item.code);
          this.formControl.patchValue(values.join(','));
         },
        (cancel) => {}
    );
    return false;
  }
}
