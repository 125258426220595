// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.main-block {
  position: relative;
  height: 331px;
  width: 636px;
  left: 104px;
  top: 347px;
  border-radius: 0px;
}

.comita-aml {
  width: 171px;
  height: 37px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 37px;
  text-align: center;
  color: #FFF59F;
}

.main-text {
  width: 636px;
  height: 118px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 59px;
  color: #FFFFFF;
  margin-top: 21px;
}

.btn-div {
  position: absolute; /* добавили */
  bottom: 0;
}

.btn-1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 32px;
  gap: 8px;
  height: 64px;
  background: linear-gradient(254.71deg, #FFEFD0 0.5%, #FFB11C 99.33%);
  box-shadow: 0px 4px 12px rgba(255, 229, 161, 0.25);
  border-radius: 4px;
}

.btn-span {
  height: 32px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #012D4A;
}`, "",{"version":3,"sources":["webpack://./src/app/base/layout/homePage.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,kBAAA;AAEF;;AACA;EACE,YAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AAEF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA,EAAA,aAAA;EACD,SAAA;AACD;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,QAAA;EACA,YAAA;EACA,oEAAA;EACA,kDAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AACF","sourcesContent":[".main-block {\n  position: relative;\n  height: 331px;\n  width: 636px;\n  left: 104px;\n  top: 347px;\n  border-radius: 0px;\n}\n\n.comita-aml {\n  width: 171px;\n  height: 37px;\n  font-family: 'Segoe UI';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 28px;\n  line-height: 37px;\n  text-align: center;\n  color: #FFF59F;\n}\n\n\n.main-text {\n  width: 636px;\n  height: 118px;\n  font-family: 'Segoe UI';\n  font-style: normal;\n  font-weight: 700;\n  font-size: 44px;\n  line-height: 59px;\n  color: #FFFFFF;\n  margin-top: 21px;\n}\n\n.btn-div {\n  position:absolute; /* добавили */\n\tbottom:0;\n}\n\n.btn-1 {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  padding: 16px 32px;\n  gap: 8px;\n  height: 64px;\n  background: linear-gradient(254.71deg, #FFEFD0 0.5%, #FFB11C 99.33%);\n  box-shadow: 0px 4px 12px rgba(255, 229, 161, 0.25);\n  border-radius: 4px;\n}\n\n.btn-span {\n  height: 32px;\n  font-family: 'Segoe UI';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 32px;\n  text-align: center;\n  color: #012D4A;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
