import {Injectable} from '@angular/core';
import {IconDefinition, faSortAmountUp, faSortAmountDown, faCopy, faChevronDown, faChevronUp, 
  faPrint, faTrash, faEnvelope, faExternalLinkSquareAlt, faDownload, faSpinner, faExclamationTriangle, 
  faFile, faCheck, faFlag, faEllipsisV, faCalendarAlt, faFolderOpen, faSearch, faPen, faTimes, faSave, 
  faUserTag, faPaperPlane, faFileImport, faUser, faUserCheck, 
  faUserPlus, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

@Injectable()
export class IconsService {
  private _faIcons = {
    plus: faPlus,
    minus: faMinus,
    addUser: faUserPlus,
    users: faUserTag,
    Agreements: faUserCheck,
    Operator: faUser,
    Senders: faPaperPlane,
    save: faSave,
    close: faTimes,
    edit: faPen,
    print: faPrint,
    search: faSearch,
    folderOpen: faFolderOpen,
    calendar: faCalendarAlt,
    ellips: faEllipsisV,
    flag: faFlag,
    check: faCheck,
    file: faFile,
    exclamationTriangle: faExclamationTriangle,
    spinner: faSpinner,
    download: faDownload,
    externalLink: faExternalLinkSquareAlt,
    mail: faEnvelope,
    trash: faTrash,
    chevronUp: faChevronUp,
    chevronDown: faChevronDown,
    copy: faCopy,
    importFile: faFileImport,
    sortUp: faSortAmountUp,
    sortDown: faSortAmountDown
  };
  private _svgIcons = {
    attachSign: '../../assets/svg/attachSign.svg',
    attachFile: '../../assets/svg/attach.svg',
  }


  public getIcons(): { [key: string]: IconDefinition } {
    return this._faIcons;
  }

  public getSvgIcons(): { [key: string]: string } {
    return this._svgIcons;
  }
}
