// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signin {
  border: 1px solid #abbce1;
  border-radius: 0.375rem;
  padding: 1rem;
}
.signin__title {
  margin: 1rem 0 2.625rem 0;
}
.signin__nav {
  display: flex;
  margin-bottom: 1.25rem;
  border: 1px solid #abbce1;
  border-radius: 0.375rem;
  justify-content: space-evenly;
  align-items: center;
}
.signin__nav-item {
  flex: 1 0;
}
.signin__nav-item.disabled-tab {
  pointer-events: none;
  cursor: not-allowed;
  -webkit-user-select: none;
          user-select: none;
}
.signin__nav-link {
  display: flex;
  justify-content: center;
  border: 0;
  text-decoration: none;
  padding: 0.5rem;
  font-weight: 600;
  color: #707070;
  width: 100%;
  cursor: pointer;
}
.signin__nav-link.active-link {
  background-color: #f2f6ff;
  border-radius: 0.375rem;
  color: #1f2328;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/auth.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,uBAAA;EACA,aAAA;AACJ;AACI;EACI,yBAAA;AACR;AAEI;EACI,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,uBAAA;EACA,6BAAA;EACA,mBAAA;AAAR;AAEQ;EACE,SAAA;AAAV;AACU;EACE,oBAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;AACZ;AAGQ;EACE,aAAA;EACA,uBAAA;EACA,SAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;AADV;AAGU;EACE,yBAAA;EACA,uBAAA;EACA,cAAA;AADZ","sourcesContent":[".signin {\n    border: 1px solid #abbce1;\n    border-radius: 0.375rem;\n    padding: 1rem;\n\n    &__title {\n        margin: 1rem 0 2.625rem 0;\n    }\n\n    &__nav {\n        display: flex;\n        margin-bottom: 1.25rem;\n        border: 1px solid #abbce1;\n        border-radius: 0.375rem;\n        justify-content: space-evenly;\n        align-items: center;\n\n        &-item {\n          flex: 1 0;\n          &.disabled-tab {\n            pointer-events: none;\n            cursor: not-allowed;\n            user-select: none;\n          }\n        }\n\n        &-link {\n          display: flex;\n          justify-content: center;\n          border: 0;\n          text-decoration: none;\n          padding: 0.5rem;\n          font-weight: 600;\n          color: #707070;\n          width: 100%;\n          cursor: pointer;\n\n          &.active-link {\n            background-color: #f2f6ff;\n            border-radius: 0.375rem;\n            color: #1f2328;\n          }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
