// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: fixed;
  bottom: 70px;
  right: 50px;
  height: 50px;
  width: 50px;
}

.scroll-btn-wrap {
  height: 100%;
  border-radius: 50%;
  background: #fff;
  color: #212529;
  border: 1px solid #212529;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all ease-in 0.1s;
  opacity: 0.5;
}
.scroll-btn-wrap:hover {
  box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.08);
  opacity: 1;
}

.iron {
  display: block;
  height: 10px;
  width: 10px;
  transform: rotate(135deg);
  border-left: 2px solid #212529;
  border-bottom: 2px solid #212529;
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/scroll-btn/scroll-btn.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,4BAAA;EACA,YAAA;AACF;AAAE;EACE,4CAAA;EACA,UAAA;AAEJ;;AAEA;EACE,cAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,8BAAA;EACA,gCAAA;EACA,eAAA;AACF","sourcesContent":[":host{\n  display: block;\n  position: fixed;\n  bottom: 70px;\n  right: 50px;\n  height: 50px;\n  width: 50px;\n}\n\n.scroll-btn-wrap{\n  height: 100%;\n  border-radius: 50%;\n  background: #fff;\n  color: #212529;\n  border: 1px solid #212529;\n  justify-content: center;\n  flex-direction: row;\n  align-items: center;\n  display: flex;\n  cursor: pointer;\n  transition: all ease-in 0.1s;\n  opacity: 0.5;\n  &:hover{\n    box-shadow: 0 0 10px 7px rgba(0,0,0,.08);\n    opacity: 1;\n  }\n}\n\n.iron{\n  display: block;\n  height: 10px;\n  width: 10px;\n  transform: rotate(135deg);\n  border-left: 2px solid #212529;\n  border-bottom: 2px solid #212529;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
