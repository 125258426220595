export type DossierK = any;
export type DossierBool = any;
export type DossierDate = any;
export type ClientTypeType = any;
export type DossierT = any;
export type DossierT_P = any;
export type DossierT5 = DossierT;
export type DossierT6 = DossierT;
export type DossierT8 = DossierT;
export type DossierT10 = DossierT;
export type DossierT11 = DossierT;
export type DossierT17 = DossierT;
export type DossierT20 = DossierT;
export type DossierT20P = DossierT_P;
export type DossierT40 = DossierT;
export type DossierT39 = DossierT;
export type DossierT50 = DossierT;
export type DossierT50P = DossierT_P;
export type DossierT60 = DossierT;
export type DossierT60P = DossierT_P;
export type DossierT100 = DossierT;
export type DossierT100P = DossierT_P;
export type DossierT200 = DossierT;
export type DossierT250 = DossierT;
export type DossierT250P = DossierT_P;
export type DossierT2000 = DossierT;
export type DossierT4000 = DossierT;
export type DossierT4000P = DossierT_P;
export type DossierINNType = any;
export type DossierSNILSType = any;
export type DossierPOLICYOMCType = any;
export type DossierKPPType = any;
export type DossierN3 = any;
export type DossierN5 = any;
export type DossierN9 = any;
export type DossierOKPOULType = any;
export type DossierOGRNType = any;
export type DossierM50 = any;
export type TypeOrg = 'ul' | 'fl' | 'ip' | 'flcp' | 'inbul';
