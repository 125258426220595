import {Component, OnInit, Input} from '@angular/core';
import {Role} from '@amlCore/models';
import {ControlContainer, UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import {UserService} from '@amlCore/services';
import {Utils} from '@amlCore/utils';

@Component({
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ],
    selector: 'app-user-form',
    templateUrl: './user.component.html'
})
export class UserComponent implements OnInit{

    constructor(
        private readonly userSrv: UserService,
        private parent: FormGroupDirective
    ) {}
    @Input()
    roles: Role[];
    @Input()
    submitted: boolean = false;
    @Input()
    isReadOnly: boolean = false;
    successChangePass = null;
    formUserControl: UntypedFormGroup;

    ngOnInit(): void {
        this.initFormControl();
    }

    get f() {
        return this.formUserControl.controls;
    }

    generatePassword(): void {
        this.userSrv.generateTempPassUser(this.f?.id?.value).subscribe(() => this.successChangePass = true);
    }

    initFormControl(): void {
        this.formUserControl = this.parent.form;
        if (this.isReadOnly){
            this.formUserControl.disable();
        }
    }

    getCustomPatterns(): object {
        return Utils.customPatterns;
    }
}
