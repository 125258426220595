import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { BaseFilterController } from "../BaseFilterController"
import {SessionFilter} from "@amlCore/models";
import {UntypedFormBuilder} from "@angular/forms";
import {TableService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";


@Component({
    selector: "app-user-session-filter",
    templateUrl: "./userSessionFilter.component.html"
})
export class UserSessionFilterComponent extends BaseFilterController implements OnInit {
    filterObjectModel = new SessionFilter();
    placeholder = 'Поиск по пользователю или роли';
    @ViewChild('message') message: TemplateRef<any>
    test = 'John Snow'
    
    constructor(private readonly fb: UntypedFormBuilder,
                protected tableSrv: TableService,
                protected readonly activatedRoute: ActivatedRoute,) {
        super(tableSrv, activatedRoute);
    }

    ngOnInit(): void {
        this.init()
    }

    createFormModel() {
        const formConfig = new SessionFilter();
        return this.fb.group(formConfig)
    }
    
    
    getFilterDescription(): SessionFilter {
        return {
            userName: 'Имя пользователя',
            userRole: 'Роль пользователя',
            id: 'Идентификатор сессии'
        } as SessionFilter
    }

    getStartFilterModel(): SessionFilter {
        return {
            userName: '',
            userRole: '',
            id: ''
        } as SessionFilter
    }
    
}