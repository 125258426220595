import {Component, Inject, OnDestroy, OnInit} from '@angular/core';

import {UserService} from '@amlCore/services';
import {Router} from '@angular/router';
import {DOCUMENT} from "@angular/common";
import {faUniversity, faArrowAltCircleRight} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-home-page-header',
  templateUrl: './homePage.component.html',
  styleUrls: ['./homePage.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
  constructor(private userSrv: UserService, private router: Router, @Inject(DOCUMENT) private document: Document) {
  }

  icons = {
    university: faUniversity,
    r_circle: faArrowAltCircleRight
  };

  ngOnInit() {
    this.document.body.classList.add('aml-body-background-start-page');
    this.document.body.classList.add('aml-header-sp');
    this.userSrv.showMenu$.next(false);
  }


  ngOnDestroy(): void {
    this.document.body.classList.remove('aml-body-background-start-page');
    this.document.body.classList.remove('aml-header-sp');
    this.userSrv.showMenu$.next(true);
  }

  goBack() {
    this.router.navigateByUrl(this.userSrv.getStartPageUser(this.userSrv.getCurrentUser()));
  }
}
