import {CryptVersionValidate} from "./CryptVersionValidate";
import {CryptCertObject} from "./CryptCertObject";

export interface CryptAPI {
    /**
     * Получить выбранный сертификат
     */
    getSelectedCert(): any;

    /**
     * Получаем алгоритм хэш функции, который заранее вычислен при высталвении сертификата по умолчанию
     */
    getAlgorithmCert(): any;

    /**
     * сбросить сертификат по умолчанию
     */
    resetSelectedCert();
    
    /**
     * Ссылка на сам плагин для удобства
     */
    cadesplugin(): any;

    /**
     * Проверяем какой версии криптоплагин у пользователя
     * @param StringVersion - указываем какая версия минимальна
     * @param ObjectVersion - наша версия на компьютере пользователя
     */
    VersionCompare(StringVersion, ObjectVersion);

    /**
     * Проверка что мы смогли поднять криптоплагин и у нас есть все нужные плагины, exe в системе
     */
    CheckForPlugIn(): Promise<CryptVersionValidate>;

    /**
     * Выставляем сертификат которые будет у пользователя
     * @param cert - сертификат который выставлен для работы в системе
     */
    selectedCert(cert): Promise<any>;

    /**
     * Поиск сертификата по его отпечатку
     * @param certHash - отпечаток сертификата
     */
    findCertificate(certHash): Promise<any>;

    /**
     * Получить все сертификаты из хранилища которые доступны пользователю по разрешенному списку для его ЛК
     * @param thumbs - список сертификатов который назначен ему для работы в его ЛК (придет с сервера после атворизации)
     */
    getCertsFromUser(thumbs): Promise<Array<CryptCertObject>>;

    /**
     * Получить весь список валидных сертификатов в личном хранилище
     */
    getCertsStore(): Promise<Array<CryptCertObject>>;

    /**
     * Получить алгоритм хэш функции по сертификату
     * @param cert - сертификат по которому надо узнать его алгоритм
     */
    getAlgorithm(cert): Promise<{ name: string, code: string }>;

    /**
     * Генерация подписи по алгоритму хэш функции
     * @param sHashValue - данные для подписи уже в формате нужной хэш функции
     * @param cert - сертификат который ее подпишет
     */
    generateSignature(sHashValue, cert): Promise<string>;

    /**
     * Обычная подпись данных. Подписать что-то в формате base64
     * @param sHashBase64 - данные в формате base64
     * @param oCertificate - сертификат которым будет производиться подпись
     */
    signDataBase64(sHashBase64, oCertificate): Promise<string>;

    /**
     * Подпись файлов
     * @param oFile - файл который надо подписать
     * @param oCertificate - сертификат которым подписываем
     */
    signFile(oFile, oCertificate): Promise<string>;

    /**
     * Получить сертификат файлом в формате base64
     * @param certHash - отпечаток
     */
    getCertBase64(certHash): Promise<any>;
}
