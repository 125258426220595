// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-header {
  position: absolute;
  background: #D8EEFF;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 11px;
  padding: 6px 10px;
  border-radius: 4px;
  display: flex;
  max-width: 80%;
}
.search-header .filter-btn {
  color: #4D4D4D;
  margin-right: 10px;
}
.search-header .close-btn {
  color: #545454;
  margin-left: 10px;
}
.search-header .text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-header-btn-color {
  color: #0D4D77;
  background: white;
  box-shadow: none !important;
}

.search-header-input {
  border-color: #ffffff !important;
  box-shadow: none !important;
}

.search-header-label {
  margin-bottom: 0 !important;
  display: contents;
}

.search-header-input-grey {
  color: gray;
}

.col-6 {
  width: 50% !important;
}

.col-12 {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/base/searchHeader/searchHeader.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;AACJ;AAEE;EACE,cAAA;EACA,iBAAA;AAAJ;AAGE;EACE,qBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AADJ;;AAKA;EACE,cAAA;EACA,iBAAA;EACA,2BAAA;AAFF;;AAKA;EACE,gCAAA;EACA,2BAAA;AAFF;;AAKA;EACE,2BAAA;EACA,iBAAA;AAFF;;AAKA;EACE,WAAA;AAFF;;AAKA;EACE,qBAAA;AAFF;;AAKA;EACE,sBAAA;AAFF","sourcesContent":[".search-header {\n  position: absolute;\n  background: #D8EEFF;\n  margin-top: 5px;\n  margin-left: 10px;\n  font-size: 11px;\n  padding: 6px 10px;\n  border-radius: 4px;\n  display: flex;\n  max-width: 80%;\n\n  .filter-btn {\n    color: #4D4D4D;\n    margin-right: 10px;\n  }\n\n  .close-btn {\n    color: #545454;\n    margin-left: 10px;\n  }\n\n  .text {\n    display: inline-block;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n}\n\n.search-header-btn-color {\n  color: #0D4D77;\n  background: white;\n  box-shadow: none !important;\n}\n\n.search-header-input {\n  border-color: #ffffff !important;\n  box-shadow: none !important;\n}\n\n.search-header-label {\n  margin-bottom: 0 !important;\n  display: contents;\n}\n\n.search-header-input-grey {\n  color: gray;\n}\n\n.col-6 {\n  width: 50% !important;\n}\n\n.col-12 {\n  width: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
