export interface OrganizationAddressModel {
  country: string; // Страна (Код страны)
  postIndex: string; // Индекс
  region: string; // Регион (Код субъекта РФ)
  area: string; // Район
  city: string; // Город
  locality: string; // Населенный пункт
  street: string; // Улица
  house: string; // Дом (владение)
  building: string; // Корпус (строение)
  apartment: string; // Офис (квартира)
}
