import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmComponent} from "../modals";

/**
 * Вызов окна подтверждения действия.
 * При указании isOutputText true, будет выведено окно с ввозможностью ввода текста
 */
@Directive({selector: '[appConfirmAction]'})
export class ConfirmActionDirective {
  constructor(private modalService: NgbModal) {
  }

  text: string;

  @Input() set appConfirmAction(textAnswer: string) {
    this.text = textAnswer;
  }

  @Input() appConfirmTitle; // заголовок окна
  @Input() appConfirmOkBtn; // текст кнопки "Ок"
  @Input() appConfirmCancelBtn; // текст кнопки "Отмена"

  @Input() isOutputText = false;
  @Input() isActivate = true;
  @Input() isNeedCustomText = false

  @Output('confirm-click') click: any = new EventEmitter<any>();

  @HostListener('click', ['$event']) clickTarget(event) {
    event.stopPropagation();
    if (this.isActivate) {
      const modal = this.modalService.open(ConfirmComponent, {
        windowClass: "top-modal-editor",
        backdropClass: "top-modal-editor"
      });
      modal.componentInstance.text = this.text;
      modal.componentInstance.isOutputText = this.isOutputText;
      modal.componentInstance.isNeedCustomText = this.isNeedCustomText
      if (this.appConfirmTitle) {
        modal.componentInstance.title = this.appConfirmTitle;
      }
      if (this.appConfirmOkBtn) {
        modal.componentInstance.okBtn = this.appConfirmOkBtn;
      }
      if (this.appConfirmCancelBtn) {
        modal.componentInstance.cancelBtn = this.appConfirmCancelBtn;
      }
      modal.result.then(data => this.success(data), cancel => this.close(cancel));
    } else {
      this.success({status: true});
    }
  }

  success(data) {
    this.click.emit({data});
  }


  close(data) {
    // code
  }


}
