import {LicenseModel} from "./license.model";
import {OrganizationTypeEnum} from "@amlCore/enums";
import {OrganizationAddressModel} from "./organizationAddress.model";
import {IDictItemType} from "@amlCore/interfaces";

export interface OrganizationModel {
  id?: string; // Идентификатор
  orgType: OrganizationTypeEnum; // Тип организации
  regNumber?: string; // Регистрационный номер банка
  code: string; // ИНН (КИО)/БИК
  account?: string; // Номер корреспондентского счета
  name: string; // Наименование
  filial: string; // Порядковый номер филиала
  oktmo?: string; // Код  ОКТМО
  swift?: string; // SWIFT-код
  inn: string; // ИНН
  idLk?: string; // Идентификатор ЛК
  nkoVid?: string; // Код вида лица - Справочник qref_dict id = nkotype
  kpp?: string; // КПП
  okpo?: string; // ОКПО
  okved2?: IDictItemType; // ОКВЭД2
  okved2Add?: IDictItemType[]; // Дополнительные ОКВЭД2
  ogrn?: string; // ОГРН
  nfoVid?: string; //  Код вида НФО - Справочник qref_dict id = nfovid
  nfoType?: string; // Тип НФО - Справочник qref_dict id = nfotype
  rezident?: boolean; // Признак резидента
  regDate?: any; // Дата гос. регистрации
  address: OrganizationAddressModel; // Адрес регистрации

  // Данные о руководителя
  lastName: string; // Фамилия руководителя
  firstName: string; // Имя руководителя
  middleName: string; // Отчество руководителя
  post: string; // Должность руководителя
  phone: string; // Телефон
  email: string; // E-mail
  license?: LicenseModel; // Лицензия

}
